<template>
   <div class="no-print">
       <nav aria-label="Page navigation" v-if="pagination.prev_page_url || pagination.next_page_url">
           <ul class="pagination pagination1 justify-content-end mt-2">
               <li class="page-item" :class="pagination.prev_page_url==null?'opacicy-07':''">
                   <a class="page-link" href="javascript:;" @click="getPaginateData(pagination.prev_page_url)" aria-label="Previous">
                       <i class="fas fa-arrow-left"></i>
                   </a>
               </li>
               <li class="page-item" v-for="(page,index) in pagination.last_page" :key="index" :class="page==pagination.current_page?'active':''">
                   <a class="page-link" href="javascript:;" @click="getPaginateData(`${pagination.first_page_url.split('=')[0]}=${page}`)">{{page}}</a>
               </li>
               <li class="page-item" :class="pagination.next_page_url==null?'opacicy-07':''">
                   <a class="page-link" href="javascript:;" @click="getPaginateData(pagination.next_page_url)" aria-label="Next">
                       <i class="fas fa-arrow-right"></i>
                   </a>
               </li>
           </ul>
       </nav>
   </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        computed: { ...mapGetters(["pagination"]) },
        methods:{
            getPaginateData(url){

                if(url){
                    this.$store.dispatch('clearRemoveList');
                    // this.$store.commit('dataFromUrl',url);
                }
            }
        },
    }
</script>

<style scoped>
.opacicy-07{
    opacity: 0.7;
}
    .page-item:focus,.page-link:focus{
        box-shadow: none;
        padding: 0.2rem 0.75rem !important;
        border: 2px solid #F4F7FA;
    }
</style>