<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Stock</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Day With Quantity Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">
              Stock Report -#Item001 - Dell Laptop
            </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-10 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="ml-2" v-model="filter.session_year">
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>
          <select
            class="filter-input ml-2"
            v-model="items"
            @change="filterByItems"
          >
            <option
              v-for="(items, index) in dataLists3"
              :key="index"
              :value="items.id"
            >
              {{ items.name }}
            </option>
          </select>
          <select v-model="filter.branch" class="filter-input ml-2">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in dataLists2"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <ul class="ml-2">
            <li>
              <a href="javascript:;" @click="prevMonth">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li><a href="javascript:;" @click="thisMonth"> Month </a></li>
            <li>
              <a href="javascript:;" @click="nextMonth">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <div class="ml-2">
            <label class="filter-lavel mb-0 mr-1">From</label>
            <input
              type="date"
              v-model="filter.from"
              placeholder="From"
              class="filter-input"
            />
            <label class="filter-lavel mb-0 ml-1">To</label>
            <input
              type="date"
              v-model="filter.to"
              placeholder="To"
              class="ml-1 filter-input"
            />
          </div>
          <div class="ml-2">
            <input
              type="text"
              v-model="filter.key"
              class="filter-input"
              placeholder="Search..."
            />
          </div>
        </div>
        <div class="col-md-2 ml-0 pl-0">
          <select
            class="filter-input ml-2 float-right"
            v-model="route"
            @change="filterReportType"
          >
            <option value="/general-report/stock">
              Item With Quantity Wise
            </option>
            <option value="/general-report/stock/item-value">
              Item With Value Wise
            </option>
            <option value="/general-report/stock/item-state">
              Item With State Wise
            </option>
            <option value="/general-report/stock/day-quantity">
              Day With Quantity Wise
            </option>
            <option value="/general-report/stock/day-value">
              Day With Value Wise
            </option>
            <option value="/general-report/stock/month-quantity">
              Month With Quantity Wise
            </option>
            <option value="/general-report/stock/month-value">
              Month With Value Wise
            </option>
            <option value="/general-report/stock/branch-quantity">
              Branch With Quantity Wise
            </option>
            <option value="/general-report/stock/branch-value">
              Branch With Value Wise
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Opening</th>
              <th class="wd-15p">Purchase</th>
              <th class="wd-15p">Sales</th>
              <th class="wd-15p">Closing</th>
              <th class="wd-15p">Sales Order</th>
              <th class="wd-15p">Purchase Order</th>
            </tr>
          </thead>
         <tbody v-for="(items, key, index) in dataSets" :key="index">
            <tr>
              <th scope="row" class="table-start-item">{{ ++index }}</th>
              <td>{{ key }}</td>
              <td>{{ items.opening}} {{items.unit_type}}</td>
              <td>{{ items.total_purchase }} {{items.unit_type}}</td>
              <td>{{ items.total_sales }} {{items.unit_type}}</td>
              <td>{{ items.closing }} {{items.unit_type}}</td>
              <td>{{ items.total_sales_order }} {{items.unit_type}}</td>
              <td>{{ items.total_purchase_order }} {{items.unit_type}}</td>
            </tr>
          </tbody>
        
        </table>
        <Pagination />
      </div>
    </div>
  </div>
</template>
<script>
import { Search } from "../../../../../mixins/search";
import Pagination from "../../../components/pagination/pagination";
import { mapGetters } from "vuex";

export default {
  mixins: [Search],
  components: {
    Pagination,
  },
  data() {
    return {
      sessionYears: ["2018", "2019", "2020", "2021"],
      route: this.$route.path,
      items: "1",
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists3",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
  },
  methods: {
    filterReportType() {
      this.$router.push(this.route);
    },
    filterByItems() {
      this.$store.commit("setApiUrl", "api/stock/filter/item/item-quantiy-wise/day-wise");
      this.$store.commit("sendData", {
        item_id: this.items,
        year: this.filter.session_year,

      });
    },
    filterByMonth() {
      this.$store.commit("setApiUrl", "api/stock/filter/month/item-quantiy-wise/day-wise");
      this.$store.commit("sendData", {
        size: this.filter.size,
        month: this.filter.full_month,
        year: this.filter.session_year,
        id: this.items,
      });
    },
    filterByTwoDates() {
        this.$store.commit("setApiUrl", "api/stock/filter/date/item-quantiy-wise/day-wise");
        this.$store.commit("sendData",{
        from: this.filter.from,
        to: this.filter.to,
        year:this.filter.session_year,
        id: this.items,
      });
       
      },
     getData() {
      // this.$store.commit("getData9", `api/expense/summary/year/${this.filter.session_year}`);
      this.$store.commit("getData", `api/stock/item-quantity-wise/day-wise/size/${this.filter.size}/year/${this.filter.session_year}/${this.items}`);
    },
  },
  beforeMount() {
    this.$store.commit("getData3", `api/getitems`);
    this.$store.commit("getData2", `api/branch`);
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>